module.exports = [{
      plugin: require('../node_modules/@joyfulelement/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"duration":1000},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SoStronk - Win Real Cash and Rewards for Playing Games","short_name":"SoStronk","start_url":"/","background_color":"#0F1922","theme_color":"#242B34","display":"standalone","icon":"src/images/logo-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"77dc68d0ffd8e145a4ec3c09a6cc4e47"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
